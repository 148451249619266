import React from 'react';
import './effitesti.css';

const Testimonials=() => {
  return (
    <section id="testimonial" className="testimonial section">
      {/* Section Title */}
      <div className="container section-title" data-aos="fade-up">
        <h2>What clients say about EffiTrack AI</h2>
        <p>
        Listen to what they have to say about our tool and how they have benefited them.
        </p>
      </div>
      {/* End Section Title */}

      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <div className="service-item position-relative">
              <div className="icon">
                <img src="images/te1.png" alt="hamed's Testimonial" style={{ width: '160%' }} />
              </div>
              <h3>Hamed, <span><p>Finance Manager</p></span></h3>
              <p>
              Managing multiple projects with tight deadlines used to be overwhelming. EffiTrack AI has made a huge difference by helping me prioritize tasks and predict completion times accurately. 
              </p>
            </div>
          </div>
          {/* End Service Item */}

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <div className="service-item position-relative">
              <div className="icon">
                <img src="images/te2.png" alt="Jenny's Testimonial" style={{ width: '160%' }} />
              </div>
              <h3>Jenny, <span><p>Managing Director</p></span></h3>
              <p>
              EffiTrack AI has revolutionized the way we manage our projects. The AI-driven task prioritization and time prediction features have significantly improved our team's productivity. We're now able to focus on the most critical tasks and meet our deadlines with greater accuracy.
              </p>
            </div>
          </div>
          {/* End Service Item */}

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
            <div className="service-item position-relative">
              <div className="icon">
                <img src="images/te3.png" alt="James's Testimonial" style={{ width: '160%' }} />
              </div>
              <h3>James, <span><p>Civil Engineer</p></span></h3>
              <p>
              Our team has seen a noticeable boost in efficiency since implementing EffiTrack AI. The AI's ability to analyze tasks and suggest the best order to tackle them has led to smoother workflows and fewer delays. 
              </p>
            </div>
          </div>
          {/* End Service Item */}
        </div>
      </div>
    </section>
  );
}
export default Testimonials;
