import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './effihero.css'

const Effihero = (props) => {
  return (
    <div
      className={`hero-section-dupli-container1 thq-section-padding ${props.rootClassName} `}
    >
      <div
        id="home"
        className="hero-section-dupli-container2 thq-section-max-width"
      >
        {/* <div className="hero-section-dupli-container4">
          
        </div> */}
        <div className="hero-section-dupli-container3">
          <h1>
            {props.heading1 ?? (
              <Fragment>
                <h1 className="hero-section-dupli-text4 thq-heading-2">
                Boost Your Productivity with <span style={{backgroundColor:'#fff',color:'#900'}} >EffiTrack AI </span>
                 
                </h1>
              </Fragment>
            )}
          </h1>
          <span>
            {props.text3 ?? (
              <Fragment>
                <span className="hero-section-dupli-text5">
                  <span>
                  EffiTrack AI is your intelligent assistant for task management and time optimization. By combining predictive time tracking with smart task prioritization, EffiTrack AI helps you stay focused on what matters most, ensuring your projects are completed efficiently and on time.
                  </span>
                  <br className="hero-section-dupli-text7"></br>
                  <br></br>
                </span>
              </Fragment>
            )}
          </span>
          <div className="hero-section-dupli-buttons">
            <button className="hero-section-dupli-action2 thq-button-animated thq-button-outline">
              <a
                href="#demo"
                
                rel="noreferrer noopener"
                className="hero-section-dupli-text2 thq-body-small"
              >
                {props.action2 ?? (
                  <Fragment>
                    <span className="hero-section-dupli-text3">
                      Try Our Demo
                    </span>
                  </Fragment>
                )}
              </a>
            </button>
          </div>
        </div>
        
      </div>
    </div>
  )
}

Effihero.defaultProps = {
  imageSrc: '/untitled%20design%20(10).svg',
  action2: undefined,
  heading1: undefined,
  imageAlt: 'image',
  rootClassName: '',
  text3: undefined,
}

Effihero.propTypes = {
  imageSrc: PropTypes.string,
  action2: PropTypes.element,
  heading1: PropTypes.element,
  imageAlt: PropTypes.string,
  rootClassName: PropTypes.string,
  text3: PropTypes.element,
}

export default Effihero
