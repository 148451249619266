import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbardupli from '../components/navbardupli'
import HeroTerms from '../components/hero-terms'
import BodyTerms from '../components/body-terms'
import Footernew from '../components/footernew'
import './terms-conditions.css'

const TermsConditions = (props) => {
  return (
    <div id="privacypolicy" className="terms-conditions-container">
      <Helmet>
        <title>Terms-Conditions - ITGround.net</title>
        <meta property="og:title" content="Terms-Conditions - ITGround.net" />
      </Helmet>
      <Navbardupli
        home={
          <Fragment>
            <span className="terms-conditions-text10 thq-link thq-body-small">
              <span>Home</span>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="terms-conditions-text14 thq-link thq-body-small">
              Home
            </span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="terms-conditions-text15 thq-link thq-body-small">
              Features
            </span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="terms-conditions-text16 thq-body-large">
              Page One
            </span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="terms-conditions-text17 thq-body-large">
              Page Two
            </span>
          </Fragment>
        }
        page3={
          <Fragment>
            <span className="terms-conditions-text18 thq-body-large">
              Page Three
            </span>
          </Fragment>
        }
        page4={
          <Fragment>
            <span className="terms-conditions-text19 thq-body-large">
              Page Four
            </span>
          </Fragment>
        }
        link21={
          <Fragment>
            <span className="terms-conditions-text20 thq-link thq-body-small">
              About Us
            </span>
          </Fragment>
        }
        link22={
          <Fragment>
            <span className="terms-conditions-text21 thq-link thq-body-small">
              Link 1
            </span>
          </Fragment>
        }
        link31={
          <Fragment>
            <span className="terms-conditions-text22 thq-link thq-body-small">
              Link 3
            </span>
          </Fragment>
        }
        link32={
          <Fragment>
            <span className="terms-conditions-text23 thq-link thq-body-small">
              Pricing Plan
            </span>
          </Fragment>
        }
        link41={
          <Fragment>
            <span className="terms-conditions-text24 thq-link thq-body-small">
              Link 4
            </span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="terms-conditions-text25">Main Action</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="terms-conditions-text26">Free Demo</span>
          </Fragment>
        }
        link321={
          <Fragment>
            <span className="terms-conditions-text27 thq-link thq-body-small">
              Contact Us
            </span>
          </Fragment>
        }
        action21={
          <Fragment>
            <span className="terms-conditions-text28">Free Demo</span>
          </Fragment>
        }
        rootClassName="navbardupli-root-class-name2"
        page1Description={
          <Fragment>
            <span className="terms-conditions-text29 thq-body-small">
              Page One Description
            </span>
          </Fragment>
        }
        page2Description={
          <Fragment>
            <span className="terms-conditions-text30 thq-body-small">
              Page Two Description
            </span>
          </Fragment>
        }
        page3Description={
          <Fragment>
            <span className="terms-conditions-text31 thq-body-small">
              Page Three Description
            </span>
          </Fragment>
        }
        page4Description={
          <Fragment>
            <span className="terms-conditions-text32 thq-body-small">
              Page Four Description
            </span>
          </Fragment>
        }
      ></Navbardupli>
      <HeroTerms
        heading1={
          <Fragment>
            <h1 className="terms-conditions-text33 thq-heading-1">
              Terms &amp; Conditions
            </h1>
          </Fragment>
        }
        rootClassName="hero-terms-root-class-name"
      ></HeroTerms>
      <BodyTerms rootClassName="body-terms-root-class-name"></BodyTerms>
      <Footernew rootClassName="footernew-root-class-name2"></Footernew>
    </div>
  )
}

export default TermsConditions
