import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './pricing14.css'

const Pricing14 = (props) => {
  const [isMonthly, setIsMonthly] = useState(true)
  return (
    <div
      id="pricingplan"
      className={`pricing14-pricing23 thq-section-padding ${props.rootClassName} `}
    >
      <div className="pricing14-max-width thq-section-max-width">
        <div className="pricing14-section-title">
          <span>
            {props.content1 ?? (
              <Fragment>
                <span className="pricing14-text209 thq-body-small">
                  Find the Perfect Plan for Your CRM Needs
                </span>
              </Fragment>
            )}
          </span>
          <div className="pricing14-content">
            <h2>
              {props.heading1 ?? (
                <Fragment>
                  <h2 className="pricing14-text212 thq-heading-2">
                    Pricing plan
                  </h2>
                </Fragment>
              )}
            </h2>
            <p>
              {props.content2 ?? (
                <Fragment>
                  <p className="pricing14-text224 thq-body-large">
                    Our CRM platform offers flexible pricing to match your
                    business size and requirements. Choose the plan that aligns
                    with your goals and start optimizing your customer
                    management today
                  </p>
                </Fragment>
              )}
            </p>
          </div>
        </div>
        <div className="pricing14-tabs">
          {isMonthly && (
            <button
              onClick={() => setIsMonthly(true)}
              className="pricing14-button10 thq-button-animated thq-button-filled"
            >
              <span className="pricing14-text103 thq-body-small">Monthly</span>
            </button>
          )}
          {!isMonthly && (
            <button
              onClick={() => setIsMonthly(true)}
              className="pricing14-button11 thq-button-animated thq-button-outline"
            >
              <span className="thq-body-small">Monthly</span>
            </button>
          )}
          {!isMonthly && (
            <button
              onClick={() => setIsMonthly(false)}
              className="pricing14-button12 thq-button-animated thq-button-filled"
            >
              <span className="thq-body-small">Yearly</span>
            </button>
          )}
          {isMonthly && (
            <button
              onClick={() => setIsMonthly(false)}
              className="pricing14-button13 thq-button-animated thq-button-outline"
            >
              <span className="thq-body-small">Yearly</span>
            </button>
          )}
        </div>
        {isMonthly && (
          <div className="pricing14-container1">
            <div className="pricing14-column1 thq-card">
              <div className="pricing14-price10">
                <div className="pricing14-price11">
                  <p>
                    {props.plan1 ?? (
                      <Fragment>
                        <p className="pricing14-text176 thq-body-large">
                          Starter Plan
                        </p>
                      </Fragment>
                    )}
                  </p>
                  <h3>
                    {props.plan1Price ?? (
                      <Fragment>
                        <h3 className="pricing14-text167 thq-heading-3">
                          $39/month
                        </h3>
                      </Fragment>
                    )}
                  </h3>
                </div>
                <div className="pricing14-list1">
                  <div className="pricing14-list-item10">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan1Feature1 ?? (
                        <Fragment>
                          <span className="pricing14-text200 thq-body-small">
                            Core CRM functionalities
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item11">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan1Feature2 ?? (
                        <Fragment>
                          <span className="pricing14-text189 thq-body-small">
                            <span>Contact management</span>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item12">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan1Feature3 ?? (
                        <Fragment>
                          <span className="pricing14-text216 thq-body-small">
                            Task automation
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item13">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan1Feature32 ?? (
                        <Fragment>
                          <span className="pricing14-text229 thq-body-small">
                            Basic reporting and analytics
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item14">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan1Feature321 ?? (
                        <Fragment>
                          <span className="pricing14-text206 thq-body-small">
                            Email support
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <a
                href="https://login.itground.net/index.php/"
                target="_blank"
                rel="noreferrer noopener"
                className="pricing14-button14 thq-button-animated thq-button-outline"
              >
                <span>
                  {props.plan1Action ?? (
                    <Fragment>
                      <span className="pricing14-text198 thq-body-small">
                        Choose Plan
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </Fragment>
                  )}
                </span>
              </a>
            </div>
            <div className="pricing14-column2 thq-card">
              <div className="pricing14-price12">
                <div className="pricing14-price13">
                  <p>
                    {props.plan2 ?? (
                      <Fragment>
                        <p className="pricing14-text195 thq-body-large">
                          Growth Plan
                        </p>
                      </Fragment>
                    )}
                  </p>
                  <h3>
                    {props.plan2Price ?? (
                      <Fragment>
                        <h3 className="pricing14-text173 thq-heading-3">
                          $99/month
                        </h3>
                      </Fragment>
                    )}
                  </h3>
                </div>
                <div className="pricing14-list2">
                  <div className="pricing14-list-item15">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature1 ?? (
                        <Fragment>
                          <span className="pricing14-text221 thq-body-small">
                            Advanced CRM features
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item16">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature2 ?? (
                        <Fragment>
                          <span className="pricing14-text228 thq-body-small">
                            Predictive analytics
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item17">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature3 ?? (
                        <Fragment>
                          <span className="pricing14-text220 thq-body-small">
                            Multi-channel communication (email, chat, social)
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item18">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature4 ?? (
                        <Fragment>
                          <span className="pricing14-text208 thq-body-small">
                            Customizable reporting
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item19">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature42 ?? (
                        <Fragment>
                          <span className="pricing14-text184 thq-body-small">
                            Enhanced automation
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item20">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature421 ?? (
                        <Fragment>
                          <span className="pricing14-text226 thq-body-small">
                            Phone and email support
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <a
                href="https://login.itground.net/index.php/"
                target="_blank"
                rel="noreferrer noopener"
                className="pricing14-button15 thq-button-animated thq-button-filled"
              >
                <span>
                  {props.plan2Action ?? (
                    <Fragment>
                      <span className="pricing14-text211 thq-body-small">
                        Choose Plan
                      </span>
                    </Fragment>
                  )}
                </span>
              </a>
            </div>
            <div className="pricing14-column3 thq-card">
              <div className="pricing14-price14">
                <div className="pricing14-price15">
                  <p>
                    {props.plan3 ?? (
                      <Fragment>
                        <p className="pricing14-text182 thq-body-large">
                          Enterprise Plan
                        </p>
                      </Fragment>
                    )}
                  </p>
                  <h3>
                    {props.plan3Price ?? (
                      <Fragment>
                        <h3 className="pricing14-text203 thq-heading-3">
                          $149/mo
                        </h3>
                      </Fragment>
                    )}
                  </h3>
                </div>
                <div className="pricing14-list3">
                  <div className="pricing14-list-item21">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature1 ?? (
                        <Fragment>
                          <span className="pricing14-text165 thq-body-small">
                            Full CRM capabilities
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item22">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature2 ?? (
                        <Fragment>
                          <span className="pricing14-text177 thq-body-small">
                            Advanced customization and integrations
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item23">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature3 ?? (
                        <Fragment>
                          <span className="pricing14-text233 thq-body-small">
                            Comprehensive analytics and dashboards
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item24">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature4 ?? (
                        <Fragment>
                          <span className="pricing14-text180 thq-body-small">
                            Dedicated account manager
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item25">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature5 ?? (
                        <Fragment>
                          <span className="pricing14-text214 thq-body-small">
                            Priority support and training
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item26">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature52 ?? (
                        <Fragment>
                          <span className="pricing14-text205 thq-body-small">
                            High-level security features
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <a
                href="https://login.itground.net/index.php/"
                target="_blank"
                rel="noreferrer noopener"
                className="pricing14-button16 thq-button-animated thq-button-filled"
              >
                <span>
                  {props.plan3Action ?? (
                    <Fragment>
                      <span className="pricing14-text166 thq-body-small">
                        Choose Plan
                      </span>
                    </Fragment>
                  )}
                </span>
              </a>
            </div>
          </div>
        )}
        {!isMonthly && (
          <div className="pricing14-container2">
            <div className="pricing14-column4 thq-card">
              <div className="pricing14-price16">
                <div className="pricing14-price17">
                  <span>
                    {props.plan11 ?? (
                      <Fragment>
                        <span className="pricing14-text202 thq-body-large">
                          Starter Plan
                        </span>
                      </Fragment>
                    )}
                  </span>
                  <h3>
                    {props.plan1Price1 ?? (
                      <Fragment>
                        <h3 className="pricing14-text222 thq-heading-3">
                          $399/year
                        </h3>
                      </Fragment>
                    )}
                  </h3>
                </div>
                <div className="pricing14-list4">
                  <div className="pricing14-list-item27">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan1Feature11 ?? (
                        <Fragment>
                          <span className="pricing14-text185 thq-body-small">
                            <span>Core CRM functionalities</span>
                            <br></br>
                            <br></br>
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item28">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan1Feature21 ?? (
                        <Fragment>
                          <span className="pricing14-text227 thq-body-small">
                            Contact management
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item29">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan1Feature31 ?? (
                        <Fragment>
                          <span className="pricing14-text218 thq-body-small">
                            Task automation
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item30">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan1Feature311 ?? (
                        <Fragment>
                          <span className="pricing14-text196 thq-body-small">
                            Basic reporting and analytics
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item31">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan1Feature3111 ?? (
                        <Fragment>
                          <span className="pricing14-text217 thq-body-small">
                            Email support
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item32">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan1Feature31112 ?? (
                        <Fragment>
                          <span className="pricing14-text215 thq-body-small">
                            Advanced Reporting
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item33">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan1Feature311111 ?? (
                        <Fragment>
                          <span className="pricing14-text169 thq-body-small">
                            Priority Support
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <button className="pricing14-button17 thq-button-animated thq-button-outline">
                <span>
                  {props.plan1Action1 ?? (
                    <Fragment>
                      <span className="pricing14-text171 thq-body-small">
                        Choose Plan
                      </span>
                    </Fragment>
                  )}
                </span>
              </button>
            </div>
            <div className="pricing14-column5 thq-card">
              <div className="pricing14-price18">
                <div className="pricing14-price19">
                  <span>
                    {props.plan21 ?? (
                      <Fragment>
                        <span className="pricing14-text170 thq-body-large">
                          Growth Plan
                        </span>
                      </Fragment>
                    )}
                  </span>
                  <h3>
                    {props.plan2Price1 ?? (
                      <Fragment>
                        <h3 className="pricing14-text168 thq-heading-3">
                          $999/year
                        </h3>
                      </Fragment>
                    )}
                  </h3>
                </div>
                <div className="pricing14-list5">
                  <div className="pricing14-list-item34">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature11 ?? (
                        <Fragment>
                          <span className="pricing14-text204 thq-body-small">
                            Advanced CRM features
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item35">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature21 ?? (
                        <Fragment>
                          <span className="pricing14-text172 thq-body-small">
                            Predictive analytics
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item36">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature31 ?? (
                        <Fragment>
                          <span className="pricing14-text213 thq-body-small">
                            Multi-channel communication (email, chat, social)
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item37">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature41 ?? (
                        <Fragment>
                          <span className="pricing14-text179 thq-body-small">
                            Customizable reporting
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item38">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature411 ?? (
                        <Fragment>
                          <span className="pricing14-text225 thq-body-small">
                            Enhanced automation
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item39">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature4111 ?? (
                        <Fragment>
                          <span className="pricing14-text219 thq-body-small">
                            Phone and email support
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item40">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature41111 ?? (
                        <Fragment>
                          <span className="pricing14-text181 thq-body-small">
                            Integration with Advanced Tools
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item41">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature411111 ?? (
                        <Fragment>
                          <span className="pricing14-text199 thq-body-small">
                            Enhanced Data Security
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <button className="pricing14-button18 thq-button-animated thq-button-filled">
                <span>
                  {props.plan2Action1 ?? (
                    <Fragment>
                      <span className="pricing14-text201 thq-body-small">
                        Choose Plan
                      </span>
                    </Fragment>
                  )}
                </span>
              </button>
            </div>
            <div className="pricing14-column6 thq-card">
              <div className="pricing14-price20">
                <div className="pricing14-price21">
                  <span>
                    {props.plan31 ?? (
                      <Fragment>
                        <span className="pricing14-text232 thq-body-large">
                          Enterprise plan
                        </span>
                      </Fragment>
                    )}
                  </span>
                  <h3>
                    {props.plan3Price1 ?? (
                      <Fragment>
                        <h3 className="pricing14-text223 thq-heading-3">
                          $1499/month
                        </h3>
                      </Fragment>
                    )}
                  </h3>
                </div>
                <div className="pricing14-list6">
                  <div className="pricing14-list-item42">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature11 ?? (
                        <Fragment>
                          <span className="pricing14-text197 thq-body-small">
                            Full CRM capabilities
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item43">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature21 ?? (
                        <Fragment>
                          <span className="pricing14-text175 thq-body-small">
                            Advanced customization and integrations
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item44">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature31 ?? (
                        <Fragment>
                          <span className="pricing14-text183 thq-body-small">
                            Comprehensive analytics and dashboards
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item45">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature41 ?? (
                        <Fragment>
                          <span className="pricing14-text230 thq-body-small">
                            Dedicated account manager
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item46">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature51 ?? (
                        <Fragment>
                          <span className="pricing14-text178 thq-body-small">
                            Priority support and training
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item47">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature511 ?? (
                        <Fragment>
                          <span className="pricing14-text231 thq-body-small">
                            High-level security features
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item48">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature5111 ?? (
                        <Fragment>
                          <span className="pricing14-text174 thq-body-small">
                            Custom API Integrations
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing14-list-item49">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature51111 ?? (
                        <Fragment>
                          <span className="pricing14-text207 thq-body-small">
                            Enterprise-Level Support and Training
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <button className="pricing14-button19 thq-button-animated thq-button-filled">
                <span>
                  {props.plan3Action1 ?? (
                    <Fragment>
                      <span className="pricing14-text210 thq-body-small">
                        Choose Plan
                      </span>
                    </Fragment>
                  )}
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

Pricing14.defaultProps = {
  plan3Feature1: undefined,
  plan3Action: undefined,
  plan1Price: undefined,
  plan2Price1: undefined,
  plan1Feature311111: undefined,
  plan21: undefined,
  plan1Action1: undefined,
  plan2Feature21: undefined,
  plan2Price: undefined,
  plan3Feature5111: undefined,
  plan3Feature21: undefined,
  plan1: undefined,
  plan3Feature2: undefined,
  plan3Feature51: undefined,
  plan2Feature41: undefined,
  plan3Feature4: undefined,
  plan2Feature41111: undefined,
  plan3: undefined,
  plan3Feature31: undefined,
  plan2Feature42: undefined,
  plan1Feature11: undefined,
  plan1Feature2: undefined,
  plan2: undefined,
  plan1Feature311: undefined,
  rootClassName: '',
  plan3Feature11: undefined,
  plan1Action: undefined,
  plan2Feature411111: undefined,
  plan1Feature1: undefined,
  plan2Action1: undefined,
  plan11: undefined,
  plan3Price: undefined,
  plan2Feature11: undefined,
  plan3Feature52: undefined,
  plan1Feature321: undefined,
  plan3Feature51111: undefined,
  plan2Feature4: undefined,
  content1: undefined,
  plan3Action1: undefined,
  plan2Action: undefined,
  heading1: undefined,
  plan2Feature31: undefined,
  plan3Feature5: undefined,
  plan1Feature31112: undefined,
  plan1Feature3: undefined,
  plan1Feature3111: undefined,
  plan1Feature31: undefined,
  plan2Feature4111: undefined,
  plan2Feature3: undefined,
  plan2Feature1: undefined,
  plan1Price1: undefined,
  plan3Price1: undefined,
  content2: undefined,
  plan2Feature411: undefined,
  plan2Feature421: undefined,
  plan1Feature21: undefined,
  plan2Feature2: undefined,
  plan1Feature32: undefined,
  plan3Feature41: undefined,
  plan3Feature511: undefined,
  plan31: undefined,
  plan3Feature3: undefined,
}

Pricing14.propTypes = {
  plan3Feature1: PropTypes.element,
  plan3Action: PropTypes.element,
  plan1Price: PropTypes.element,
  plan2Price1: PropTypes.element,
  plan1Feature311111: PropTypes.element,
  plan21: PropTypes.element,
  plan1Action1: PropTypes.element,
  plan2Feature21: PropTypes.element,
  plan2Price: PropTypes.element,
  plan3Feature5111: PropTypes.element,
  plan3Feature21: PropTypes.element,
  plan1: PropTypes.element,
  plan3Feature2: PropTypes.element,
  plan3Feature51: PropTypes.element,
  plan2Feature41: PropTypes.element,
  plan3Feature4: PropTypes.element,
  plan2Feature41111: PropTypes.element,
  plan3: PropTypes.element,
  plan3Feature31: PropTypes.element,
  plan2Feature42: PropTypes.element,
  plan1Feature11: PropTypes.element,
  plan1Feature2: PropTypes.element,
  plan2: PropTypes.element,
  plan1Feature311: PropTypes.element,
  rootClassName: PropTypes.string,
  plan3Feature11: PropTypes.element,
  plan1Action: PropTypes.element,
  plan2Feature411111: PropTypes.element,
  plan1Feature1: PropTypes.element,
  plan2Action1: PropTypes.element,
  plan11: PropTypes.element,
  plan3Price: PropTypes.element,
  plan2Feature11: PropTypes.element,
  plan3Feature52: PropTypes.element,
  plan1Feature321: PropTypes.element,
  plan3Feature51111: PropTypes.element,
  plan2Feature4: PropTypes.element,
  content1: PropTypes.element,
  plan3Action1: PropTypes.element,
  plan2Action: PropTypes.element,
  heading1: PropTypes.element,
  plan2Feature31: PropTypes.element,
  plan3Feature5: PropTypes.element,
  plan1Feature31112: PropTypes.element,
  plan1Feature3: PropTypes.element,
  plan1Feature3111: PropTypes.element,
  plan1Feature31: PropTypes.element,
  plan2Feature4111: PropTypes.element,
  plan2Feature3: PropTypes.element,
  plan2Feature1: PropTypes.element,
  plan1Price1: PropTypes.element,
  plan3Price1: PropTypes.element,
  content2: PropTypes.element,
  plan2Feature411: PropTypes.element,
  plan2Feature421: PropTypes.element,
  plan1Feature21: PropTypes.element,
  plan2Feature2: PropTypes.element,
  plan1Feature32: PropTypes.element,
  plan3Feature41: PropTypes.element,
  plan3Feature511: PropTypes.element,
  plan31: PropTypes.element,
  plan3Feature3: PropTypes.element,
}

export default Pricing14
