import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import ContactForm7 from './contact-form7'
import './contact-form.css'

const ContactForm = (props) => {
  return (
    <div
      id="contact"
      className={`contact-form-container ${props.rootClassName} `}
    >
      <ContactForm7
        email={
          <Fragment>
            <span className="contact-form-text1 thq-body-small">
              support@itground.net
            </span>
          </Fragment>
        }
        phone={
          <Fragment>
            <span className="contact-form-text2 thq-body-small">
              +94 72 334 4583
            </span>
          </Fragment>
        }
        action={
          <Fragment>
            <span className="contact-form-text3 thq-body-small">
              Contact us now
            </span>
          </Fragment>
        }
        address={
          <Fragment>
            <span className="contact-form-text4 thq-body-small">
              33 , Aluthganthota Road, Kandy 20000, Sri lanka
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="contact-form-text5 thq-body-small">
              <span>Have questions or need assistance?</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </Fragment>
        }
        content2={
          <Fragment>
            <span className="contact-form-text8 thq-body-small">
              Our team is here to help!
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <h2 className="contact-form-text9 thq-heading-2">Get in touch</h2>
          </Fragment>
        }
        rootClassName="contact-form7-root-class-name"
      ></ContactForm7>
    </div>
  )
}

ContactForm.defaultProps = {
  rootClassName: '',
}

ContactForm.propTypes = {
  rootClassName: PropTypes.string,
}

export default ContactForm
