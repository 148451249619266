import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './effibenefit.css'

const EffiBenefit = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="testimonial17-max-width thq-section-max-width">
        <div className="testimonial17-container">
          <h2>
            {props.heading1 ?? (
              <Fragment>
                <h2 className="testimonial17-text38">
                How Does EffiTrack AI Work?
                </h2>
              </Fragment>
            )}
          </h2>
          <span>
            {props.content1 ?? (
              <Fragment>
                {/* <span className="testimonial17-text16 thq-body-small">
                  Our clients from diverse industries and regions share their
                  experiences with our CRM platform. Discover how businesses of
                  all sizes have transformed their customer interactions and
                  driven success through our innovative solutions
                </span> */}
              </Fragment>
            )}
          </span>
        </div>
        <div id="work" className="thq-grid-2">
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div data-animated="true" className="thq-card testimonial17-card">
                <div className="testimonial17-container02">
                  <img
                    alt={props.author1Alt}
                    src='images/1.svg'
                    className="testimonial17-image"
                  />
                  <div className="testimonial17-container03">
                    <strong>
                      {props.author1Name ?? (
                        <Fragment>
                          <strong className="testimonial17-text40 thq-body-large">
                          Analyze Your Tasks
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    {/* <span>
                      {props.author1Position ?? (
                        <Fragment>
                          <span className="testimonial17-text33 thq-body-small">
                            Marketing Director, TechFusion Inc
                          </span>
                        </Fragment>
                      )}
                    </span> */}
                  </div>
                </div>
                <span>
                  {props.review1 ?? (
                    <Fragment>
                      <span className="testimonial17-text34 thq-body-small">
                        <span>
                        EffiTrack AI reviews your tasks, deadlines, and workload to build a detailed profile. This analysis provides the foundation for accurate predictions and intelligent prioritization.
                        </span>
                        <br></br>
                        <br></br>
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card1"
              >
                <div className="testimonial17-container04">
                  <img
                    alt={props.author2Alt}
                    src='images/2.svg'
                    className="testimonial17-image1"
                  />
                  <div className="testimonial17-container05">
                    <strong>
                      {props.author2Name ?? (
                        <Fragment>
                          <strong className="testimonial17-text15 thq-body-large">
                          Predict and Prioritize
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    {/* <span>
                      {props.author2Position ?? (
                        <Fragment>
                          <span className="testimonial17-text14 thq-body-small">
                            CTO, NextGen Enterprises
                          </span>
                        </Fragment>
                      )}
                    </span> */}
                  </div>
                </div>
                <span>
                  {props.review2 ?? (
                    <Fragment>
                      <span className="testimonial17-text22 thq-body-small">
                        <span>
                        EffiTrack AI predicts the time needed for each task and prioritizes them based on urgency, deadlines, and impact. The AI updates priorities in real-time to keep you focused on what matters most.
                        </span>
                        <br></br>
                        <br></br>
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card2"
              >
                <div className="testimonial17-container06">
                  <img
                    alt={props.author3Alt}
                    src='images/3.svg'
                    className="testimonial17-image2"
                  />
                  <div className="testimonial17-container07">
                    <strong>
                      {props.author3Name ?? (
                        <Fragment>
                          <strong className="testimonial17-text26 thq-body-large">
                          Provide Recommendations
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    {/* <span>
                      {props.author3Position ?? (
                        <Fragment>
                          <span className="testimonial17-text31 thq-body-small">
                            COO, LankaTech Solutions
                          </span>
                        </Fragment>
                      )}
                    </span> */}
                  </div>
                </div>
                <span>
                  {props.review3 ?? (
                    <Fragment>
                      <span className="testimonial17-text27 thq-body-small">
                        <span>
                        Get personalized recommendations to optimize your workflow, including when to start tasks, group similar activities, and take breaks for improved efficiency.
                        </span>
                        <br></br>
                        <br></br>
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card3"
              >
                <div className="testimonial17-container08">
                  <img
                    alt={props.author4Alt}
                    src='images/4.svg'
                    className="testimonial17-image3"
                  />
                  <div className="testimonial17-container09">
                    <strong>
                      {props.author4Name ?? (
                        <Fragment>
                          <strong className="testimonial17-text39 thq-body-large">
                          Adapt and Optimize
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    {/* <span>
                      {props.author4Position ?? (
                        <Fragment>
                          <span className="testimonial17-text32 thq-body-small">
                            Operations Manager, Singapore Innovations
                          </span>
                        </Fragment>
                      )}
                    </span> */}
                  </div>
                </div>
                <span>
                  {props.review4 ?? (
                    <Fragment>
                      <span className="testimonial17-text17 thq-body-small">
                        <span>
                        EffiTrack AI adapts to changes in your workload and progress, adjusting predictions and priorities in real-time to ensure your task list remains optimized and manageable.
                        </span>
                        <br></br>
                        <br></br>
                        <br></br>
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

EffiBenefit.defaultProps = {
  author2Position: undefined,
  author2Name: undefined,
  content1: undefined,
  author2Src:
    'https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE1fHxhdmF0YXJ8ZW58MHx8fHwxNzE2MzgzNTUyfDA&ixlib=rb-4.0.3&w=200',
  review4: undefined,
  review2: undefined,
  author3Name: undefined,
  author3Src:
    'https://images.unsplash.com/photo-1544725176-7c40e5a71c5e?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEyfHxhdmF0YXJ8ZW58MHx8fHwxNzE2MzgzNTUyfDA&ixlib=rb-4.0.3&w=200',
  review3: undefined,
  author3Position: undefined,
  author4Position: undefined,
  author3Alt: 'image',
  author2Alt: 'image',
  author1Src:
    'https://images.unsplash.com/photo-1599566150163-29194dcaad36?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDN8fGF2YXRhcnxlbnwwfHx8fDE3MTYzODM1NTJ8MA&ixlib=rb-4.0.3&w=200',
  author1Position: undefined,
  review1: undefined,
  heading1: undefined,
  author1Alt: 'image',
  author4Name: undefined,
  author4Alt: 'image',
  author1Name: undefined,
  author4Src:
    'https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDMxfHxhdmF0YXJ8ZW58MHx8fHwxNzE2MzgzNTY2fDA&ixlib=rb-4.0.3&w=200',
}

EffiBenefit.propTypes = {
  author2Position: PropTypes.element,
  author2Name: PropTypes.element,
  content1: PropTypes.element,
  author2Src: PropTypes.string,
  review4: PropTypes.element,
  review2: PropTypes.element,
  author3Name: PropTypes.element,
  author3Src: PropTypes.string,
  review3: PropTypes.element,
  author3Position: PropTypes.element,
  author4Position: PropTypes.element,
  author3Alt: PropTypes.string,
  author2Alt: PropTypes.string,
  author1Src: PropTypes.string,
  author1Position: PropTypes.element,
  review1: PropTypes.element,
  heading1: PropTypes.element,
  author1Alt: PropTypes.string,
  author4Name: PropTypes.element,
  author4Alt: PropTypes.string,
  author1Name: PropTypes.element,
  author4Src: PropTypes.string,
}

export default EffiBenefit;
