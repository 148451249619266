import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbardupli from '../components/navbardupli'
import HeroSectionDupli from '../components/hero-section-dupli'
import AboutUs1 from '../components/about-us1'
import WhyChooseUs from '../components/why-choose-us'
import FeatureSection from '../components/feature-section'
import Testimonial17 from '../components/testimonial17'
import Pricing14 from '../components/pricing14'
import Faqs from '../components/faqs'
import CTA26 from '../components/cta26'
import ContactForm from '../components/contact-form'
import Footernew from '../components/footernew'
import './home.css'
import Footer from '../components/footernew'
import Nav from '../components/header1'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>ITGround.net</title>
        <meta property="og:title" content="ITGround.net" />
      </Helmet>
    
<Nav/>
      <HeroSectionDupli
        text3={
          <Fragment>
            <span className="home-text123">
              <span>
                Experience unparalleled insights and seamless interactions with
                our cutting-edge CRM platform, designed to transform how you
                connect with your customers
              </span>
              <br className="home-text125"></br>
              <br></br>
            </span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text127">Explore More</span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <h1 className="home-text128 thq-heading-2">
              Revolutionize Your Customer Relationships with Our Next Gen CRM
              Platform
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </h1>
          </Fragment>
        }
        rootClassName="hero-section-dupli-root-class-name"
      ></HeroSectionDupli>
      <AboutUs1
        text={
          <Fragment>
            <span className="home-text129 thq-heading-3">
              Redefining Customer Relationships
            </span>
          </Fragment>
        }
        text4={
          <Fragment>
            <span className="home-text130 thq-body-small">
              <span>
                Leverage our powerful CRM platform to optimize your customer
                engagement, boost productivity, and make data-driven decisions.
                Designed to scale with your business, it&apos;s the ultimate
                tool for lasting success
              </span>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        heading3={
          <Fragment>
            <h1 className="home-text134 thq-heading-2">
              Transform Your Business with Next-Gen CRM
            </h1>
          </Fragment>
        }
        rootClassName="about-us1-root-class-name"
      ></AboutUs1>
      <WhyChooseUs
        text={
          <Fragment>
            <span className="home-text135 thq-heading-3">
              Revolutionizing Customer Management
            </span>
          </Fragment>
        }
        text4={
          <Fragment>
            <span className="home-text136 thq-body-small">
              <span>
                We are pioneers in CRM technology, dedicated to delivering
                cutting-edge solutions that drive business growth. Our platform
                combines advanced AI with intuitive design to revolutionize how
                businesses manage and nurture customer relationships.
              </span>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        heading3={
          <Fragment>
            <h1 className="home-text140 thq-heading-2">
              <span>Meet the CRM Visionaries</span>
              <br></br>
            </h1>
          </Fragment>
        }
        rootClassName="why-choose-us-root-class-name"
      ></WhyChooseUs>
      <FeatureSection
        content1={
          <Fragment>
            <span className="home-text143 thq-body-small">
              <span>
                Experience comprehensive CRM capabilities designed to enhance
                efficiency, personalize interactions, and ensure robust security
                for optimal business performance
              </span>
              <br className="home-text145"></br>
              <br></br>
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <h2 className="home-text147 thq-heading-2">Our Features</h2>
          </Fragment>
        }
        feature1Title={
          <Fragment>
            <h2 className="home-text148 thq-heading-2">Predictive Analytics</h2>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <h2 className="home-text149 thq-heading-2">Dynamic Automation</h2>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <h1 className="home-text150 thq-heading-2">Centralized Data Hub</h1>
          </Fragment>
        }
        rootClassName="feature-section-root-class-name"
        feature4Title1={
          <Fragment>
            <h1 className="home-text151 thq-heading-2">
              Flexible Integrations
            </h1>
          </Fragment>
        }
        feature5Title2={
          <Fragment>
            <h1 className="home-text152 thq-heading-2">
              Advanced Personalization
            </h1>
          </Fragment>
        }
        feature5Title11={
          <Fragment>
            <h1 className="home-text153 thq-heading-2">
              Robust Security Protocols
            </h1>
          </Fragment>
        }
        feature1Description={
          <Fragment>
            <span className="home-text154 thq-body-small">
              <span>
                Leverage AI to forecast customer behaviors and trends, enabling
                proactive decision-making and strategic planning.
              </span>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text158 thq-body-small">
              <span>
                Streamline operations with intelligent automation that adapts to
                your workflows, enhancing efficiency and reducing manual
                intervention.
              </span>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="home-text162 thq-body-small">
              Access and manage all customer information from a single,
              intuitive dashboard for comprehensive insights and strategic
              oversight.
            </span>
          </Fragment>
        }
        feature4Description1={
          <Fragment>
            <span className="home-text163 thq-body-small">
              <span>
                Seamlessly integrate with a wide range of business applications
                and tools, ensuring a cohesive ecosystem and enhanced
                functionality.
              </span>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        feature5Description2={
          <Fragment>
            <span className="home-text167 thq-body-small">
              <span>
                Utilize data-driven insights to create highly tailored customer
                interactions and marketing campaigns, driving deeper engagement
                and loyalty.
              </span>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        feature5Description11={
          <Fragment>
            <span className="home-text171 thq-body-small">
              Safeguard sensitive information with cutting-edge security
              features, ensuring data protection and compliance with industry
              standards.
            </span>
          </Fragment>
        }
      ></FeatureSection>
      <Testimonial17
        review1={
          <Fragment>
            <span className="home-text172 thq-body-small">
              <span>
                &quot;This CRM has revolutionized our sales and marketing
                strategies. AI-driven insights and automation have significantly
                boosted our productivity.&quot;
              </span>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        review2={
          <Fragment>
            <span className="home-text176 thq-body-small">
              <span>
                &quot;The intuitive design and advanced features of this CRM
                have streamlined our processes and enhanced our customer
                relationships. Highly recommended!&quot;
              </span>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        review3={
          <Fragment>
            <span className="home-text180 thq-body-small">
              <span>
                &quot;The advanced features and seamless integration of this CRM
                platform have transformed our customer interactions. It&apos;s a
                game-changer for our business.&quot;
              </span>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        review4={
          <Fragment>
            <span className="home-text184 thq-body-small">
              <span>
                &quot;We’ve seen remarkable improvements in customer engagement
                and operational efficiency since adopting this CRM. It’s an
                invaluable asset to our team.&quot;
              </span>
              <br></br>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text189 thq-body-small">
              Our clients from diverse industries and regions share their
              experiences with our CRM platform. Discover how businesses of all
              sizes have transformed their customer interactions and driven
              success through our innovative solutions
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <h2 className="home-text190 thq-heading-2">
              Hear from Our Satisfied Customers
            </h2>
          </Fragment>
        }
        author1Src="images/3-200h.png"
        author2Src="images/4-200h.png"
        author3Src="images/1-200h.png"
        author4Src="images/22-200h.png"
        author1Name={
          <Fragment>
            <strong className="home-text191 thq-body-large">
              Michael Johnson
            </strong>
          </Fragment>
        }
        author2Name={
          <Fragment>
            <strong className="home-text192 thq-body-large">
              Rajesh Kumar
            </strong>
          </Fragment>
        }
        author3Name={
          <Fragment>
            <strong className="home-text193 thq-body-large">
              Anushka Perera
            </strong>
          </Fragment>
        }
        author4Name={
          <Fragment>
            <strong className="home-text194 thq-body-large">Priya Tan</strong>
          </Fragment>
        }
        author1Position={
          <Fragment>
            <span className="home-text195 thq-body-small">
              Marketing Director, TechFusion Inc
            </span>
          </Fragment>
        }
        author2Position={
          <Fragment>
            <span className="home-text196 thq-body-small">
              CTO, NextGen Enterprises
            </span>
          </Fragment>
        }
        author3Position={
          <Fragment>
            <span className="home-text197 thq-body-small">
              COO, LankaTech Solutions
            </span>
          </Fragment>
        }
        author4Position={
          <Fragment>
            <span className="home-text198 thq-body-small">
              Operations Manager, Singapore Innovations
            </span>
          </Fragment>
        }
      ></Testimonial17>
      <Pricing14
        plan1={
          <Fragment>
            <p className="home-text199 thq-body-large">Starter Plan</p>
          </Fragment>
        }
        plan2={
          <Fragment>
            <p className="home-text200 thq-body-large">Growth Plan</p>
          </Fragment>
        }
        plan3={
          <Fragment>
            <p className="home-text201 thq-body-large">Enterprise Plan</p>
          </Fragment>
        }
        plan11={
          <Fragment>
            <span className="home-text202 thq-body-large">Starter Plan</span>
          </Fragment>
        }
        plan21={
          <Fragment>
            <span className="home-text203 thq-body-large">Growth Plan</span>
          </Fragment>
        }
        plan31={
          <Fragment>
            <span className="home-text204 thq-body-large">Enterprise plan</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text205 thq-body-small">
              Find the Perfect Plan for Your CRM Needs
            </span>
          </Fragment>
        }
        content2={
          <Fragment>
            <p className="home-text206 thq-body-large">
              Our CRM platform offers flexible pricing to match your business
              size and requirements. Choose the plan that aligns with your goals
              and start optimizing your customer management today
            </p>
          </Fragment>
        }
        heading1={
          <Fragment>
            <h2 className="home-text207 thq-heading-2">Pricing plan</h2>
          </Fragment>
        }
        plan1Price={
          <Fragment>
            <h3 className="home-text208 thq-heading-3">$39/month</h3>
          </Fragment>
        }
        plan2Price={
          <Fragment>
            <h3 className="home-text209 thq-heading-3">$99/month</h3>
          </Fragment>
        }
        plan3Price={
          <Fragment>
            <h3 className="home-text210 thq-heading-3">$149/mo</h3>
          </Fragment>
        }
        plan1Action={
          <Fragment>
            <span className="home-text211 thq-body-small">
              Choose Plan
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        plan1Price1={
          <Fragment>
            <h3 className="home-text212 thq-heading-3">$399/year</h3>
          </Fragment>
        }
        plan2Action={
          <Fragment>
            <span className="home-text213 thq-body-small">Choose Plan</span>
          </Fragment>
        }
        plan2Price1={
          <Fragment>
            <h3 className="home-text214 thq-heading-3">$999/year</h3>
          </Fragment>
        }
        plan3Action={
          <Fragment>
            <span className="home-text215 thq-body-small">Choose Plan</span>
          </Fragment>
        }
        plan3Price1={
          <Fragment>
            <h3 className="home-text216 thq-heading-3">$1499/month</h3>
          </Fragment>
        }
        plan1Action1={
          <Fragment>
            <span className="home-text217 thq-body-small">Choose Plan</span>
          </Fragment>
        }
        plan2Action1={
          <Fragment>
            <span className="home-text218 thq-body-small">Choose Plan</span>
          </Fragment>
        }
        plan3Action1={
          <Fragment>
            <span className="home-text219 thq-body-small">Choose Plan</span>
          </Fragment>
        }
        plan1Feature1={
          <Fragment>
            <span className="home-text220 thq-body-small">
              Core CRM functionalities
            </span>
          </Fragment>
        }
        plan1Feature2={
          <Fragment>
            <span className="home-text221 thq-body-small">
              <span>Contact management</span>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        plan1Feature3={
          <Fragment>
            <span className="home-text227 thq-body-small">Task automation</span>
          </Fragment>
        }
        plan2Feature1={
          <Fragment>
            <span className="home-text228 thq-body-small">
              Advanced CRM features
            </span>
          </Fragment>
        }
        plan2Feature2={
          <Fragment>
            <span className="home-text229 thq-body-small">
              Predictive analytics
            </span>
          </Fragment>
        }
        plan2Feature3={
          <Fragment>
            <span className="home-text230 thq-body-small">
              Multi-channel communication (email, chat, social)
            </span>
          </Fragment>
        }
        plan2Feature4={
          <Fragment>
            <span className="home-text231 thq-body-small">
              Customizable reporting
            </span>
          </Fragment>
        }
        plan3Feature1={
          <Fragment>
            <span className="home-text232 thq-body-small">
              Full CRM capabilities
            </span>
          </Fragment>
        }
        plan3Feature2={
          <Fragment>
            <span className="home-text233 thq-body-small">
              Advanced customization and integrations
            </span>
          </Fragment>
        }
        plan3Feature3={
          <Fragment>
            <span className="home-text234 thq-body-small">
              Comprehensive analytics and dashboards
            </span>
          </Fragment>
        }
        plan3Feature4={
          <Fragment>
            <span className="home-text235 thq-body-small">
              Dedicated account manager
            </span>
          </Fragment>
        }
        plan3Feature5={
          <Fragment>
            <span className="home-text236 thq-body-small">
              Priority support and training
            </span>
          </Fragment>
        }
        rootClassName="pricing14-root-class-name"
        plan1Feature11={
          <Fragment>
            <span className="home-text237 thq-body-small">
              <span>Core CRM functionalities</span>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        plan1Feature21={
          <Fragment>
            <span className="home-text241 thq-body-small">
              Contact management
            </span>
          </Fragment>
        }
        plan1Feature31={
          <Fragment>
            <span className="home-text242 thq-body-small">Task automation</span>
          </Fragment>
        }
        plan1Feature32={
          <Fragment>
            <span className="home-text243 thq-body-small">
              Basic reporting and analytics
            </span>
          </Fragment>
        }
        plan2Feature11={
          <Fragment>
            <span className="home-text244 thq-body-small">
              Advanced CRM features
            </span>
          </Fragment>
        }
        plan2Feature21={
          <Fragment>
            <span className="home-text245 thq-body-small">
              Predictive analytics
            </span>
          </Fragment>
        }
        plan2Feature31={
          <Fragment>
            <span className="home-text246 thq-body-small">
              Multi-channel communication (email, chat, social)
            </span>
          </Fragment>
        }
        plan2Feature41={
          <Fragment>
            <span className="home-text247 thq-body-small">
              Customizable reporting
            </span>
          </Fragment>
        }
        plan2Feature42={
          <Fragment>
            <span className="home-text248 thq-body-small">
              Enhanced automation
            </span>
          </Fragment>
        }
        plan3Feature11={
          <Fragment>
            <span className="home-text249 thq-body-small">
              Full CRM capabilities
            </span>
          </Fragment>
        }
        plan3Feature21={
          <Fragment>
            <span className="home-text250 thq-body-small">
              Advanced customization and integrations
            </span>
          </Fragment>
        }
        plan3Feature31={
          <Fragment>
            <span className="home-text251 thq-body-small">
              Comprehensive analytics and dashboards
            </span>
          </Fragment>
        }
        plan3Feature41={
          <Fragment>
            <span className="home-text252 thq-body-small">
              Dedicated account manager
            </span>
          </Fragment>
        }
        plan3Feature51={
          <Fragment>
            <span className="home-text253 thq-body-small">
              Priority support and training
            </span>
          </Fragment>
        }
        plan3Feature52={
          <Fragment>
            <span className="home-text254 thq-body-small">
              High-level security features
            </span>
          </Fragment>
        }
        plan1Feature311={
          <Fragment>
            <span className="home-text255 thq-body-small">
              Basic reporting and analytics
            </span>
          </Fragment>
        }
        plan1Feature321={
          <Fragment>
            <span className="home-text256 thq-body-small">Email support</span>
          </Fragment>
        }
        plan2Feature411={
          <Fragment>
            <span className="home-text257 thq-body-small">
              Enhanced automation
            </span>
          </Fragment>
        }
        plan2Feature421={
          <Fragment>
            <span className="home-text258 thq-body-small">
              Phone and email support
            </span>
          </Fragment>
        }
        plan3Feature511={
          <Fragment>
            <span className="home-text259 thq-body-small">
              High-level security features
            </span>
          </Fragment>
        }
        plan1Feature3111={
          <Fragment>
            <span className="home-text260 thq-body-small">Email support</span>
          </Fragment>
        }
        plan2Feature4111={
          <Fragment>
            <span className="home-text261 thq-body-small">
              Phone and email support
            </span>
          </Fragment>
        }
        plan3Feature5111={
          <Fragment>
            <span className="home-text262 thq-body-small">
              Custom API Integrations
            </span>
          </Fragment>
        }
        plan1Feature31112={
          <Fragment>
            <span className="home-text263 thq-body-small">
              Advanced Reporting
            </span>
          </Fragment>
        }
        plan2Feature41111={
          <Fragment>
            <span className="home-text264 thq-body-small">
              Integration with Advanced Tools
            </span>
          </Fragment>
        }
        plan3Feature51111={
          <Fragment>
            <span className="home-text265 thq-body-small">
              Enterprise-Level Support and Training
            </span>
          </Fragment>
        }
        plan1Feature311111={
          <Fragment>
            <span className="home-text266 thq-body-small">
              Priority Support
            </span>
          </Fragment>
        }
        plan2Feature411111={
          <Fragment>
            <span className="home-text267 thq-body-small">
              Enhanced Data Security
            </span>
          </Fragment>
        }
      ></Pricing14>
      <Faqs rootClassName="faqs-root-class-name"></Faqs>
      <CTA26
        action1={
          <Fragment>
            <span className="home-text268">Contact Us</span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text269 thq-heading-3">
              Struggling with outdated systems ?
            </span>
          </Fragment>
        }
        heading11={
          <Fragment>
            <span className="home-text270 thq-heading-3">
              Get started today and experience the future of customer
              management!
            </span>
          </Fragment>
        }
        rootClassName="cta26-root-class-name"
      ></CTA26>
      <ContactForm rootClassName="contact-form-root-class-name"></ContactForm>
      {/* <Footernew rootClassName="footernew-root-class-name"></Footernew> */}
      <Footer/>
    </div>
  )
}

export default Home
