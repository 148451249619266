import React, {useState} from 'react';

import { Link } from 'react-scroll';
import './header1.css';
export default function Nav() {

    const [isNavActive, setNavActive] = useState(false);

    const handleNavToggle = () => {
        setNavActive(!isNavActive);
    };

    return (
        <>
            {/* <header id="header" className="header fixed-top"> */}
            <header id="header" className={`header fixed-top ${isNavActive ? 'mobile-nav-active' : ''}`}>
             

                <div className="branding d-flex align-items-center">
                    <div className="container position-relative d-flex align-items-center justify-content-between">
                        <a href="/" className="logo d-flex align-items-center">
                        <img src="images/logonew.svg" alt="Company Logo" className="logo" width="150px" height="auto" />

            
                        </a>

                        <nav id="navmenu" className={`navmenu ${isNavActive ? 'mobile-nav-active' : ''}`}>
                            <ul>
                                <li><a href="/" className="">Home<br /></a></li>
                                <li><a href="/#aboutus">About Us</a></li>
                                <li><a href="/#features">Features</a></li>
                                <li><a href="/#pricingplan">Pricing Plan</a></li>
                                <li><a href="/#contact">Contact Us</a></li>
                                <li class="nav-signin"><a href="https://login.itground.net/index.php/signin">Free Demo</a></li>
                                
                                
                               
                                
                                
                            </ul>
                            <i className={`mobile-nav-toggle d-xl-none bi ${isNavActive ? 'bi-x' : 'bi-list'}`} onClick={handleNavToggle}></i>
                        </nav>
                    </div>
                </div>
            </header>
        </>
    );
}
