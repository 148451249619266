
import './hero-section-dupli.css'
import React from 'react';


const HeroSectionDupli = () => {
    return (
        <section id="home" className="hero section accent-background">
    <div className="container position-relative" style={{ width: '85%', maxWidth: '100%', margin: '0 auto' }} data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-5 justify-content-between">
            <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                <h2>
                    <span>Revolutionize Your Customer Relationships with Our Next Gen CRM Platform </span>
                </h2>
                <p>
                    Experience unparalleled insights and seamless interactions with our cutting-edge CRM platform, designed to transform how you connect with your customers.
                </p>
                <div className="d-flex">
                    <a href="#aboutus" className="btn-get-started">Explore More</a>
                    <a href="/effitrack" className="btn-ai"><span>EffiTrack AI</span></a>
                </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
                <img src="images/hero.png" className="img-fluid" alt="Hero" />
            </div>
        </div>
    </div>
</section>

    

        

    );
};

export default HeroSectionDupli;
