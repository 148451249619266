import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './navbardupli.css'

const Navbardupli = (props) => {
  const [link5AccordionOpen, setLink5AccordionOpen] = useState(false)
  const [link5DropdownVisible, setLink5DropdownVisible] = useState(false)
  return (
    <header className={`navbardupli-container1 ${props.rootClassName} `}>
      <header
        data-thq="thq-navbar"
        className="navbardupli-navbar-interactive thq-section-padding"
      >
        <a
          href="https://itground.net/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            alt={props.logoAlt}
            src={props.logoSrc}
            className="navbardupli-image1"
          />
        </a>
        <div data-thq="thq-navbar-nav" className="navbardupli-desktop-menu">
          <nav className="navbardupli-links1">
            <a href="#home" className="navbardupli-link21">
              {props.link2 ?? (
                <Fragment>
                  <span className="navbardupli-text32 thq-link thq-body-small">
                    Home
                  </span>
                </Fragment>
              )}
            </a>
            <a href="#aboutus" className="navbardupli-link22">
              {props.link21 ?? (
                <Fragment>
                  <span className="navbardupli-text14 thq-link thq-body-small">
                    About Us
                  </span>
                </Fragment>
              )}
            </a>
            <a href="#features" className="navbardupli-link31">
              {props.link3 ?? (
                <Fragment>
                  <span className="navbardupli-text26 thq-link thq-body-small">
                    Features
                  </span>
                </Fragment>
              )}
            </a>
            <a href="#pricingplan" className="navbardupli-link32">
              {props.link32 ?? (
                <Fragment>
                  <span className="navbardupli-text16 thq-link thq-body-small">
                    Pricing Plan
                  </span>
                </Fragment>
              )}
            </a>
            <a href="#contact" className="navbardupli-link33">
              {props.link321 ?? (
                <Fragment>
                  <span className="navbardupli-text33 thq-link thq-body-small">
                    Contact Us
                  </span>
                </Fragment>
              )}
            </a>
          </nav>
          <div className="navbardupli-buttons1">
            <button className="navbardupli-action21 thq-button-animated thq-button-outline">
              <a
                href="https://login.itground.net/index.php/signin"
                target="_blank"
                rel="noreferrer noopener"
                className="navbardupli-text10 thq-body-small"
              >
                {props.action2 ?? (
                  <Fragment>
                    <span className="navbardupli-text35">Free Demo</span>
                  </Fragment>
                )}
              </a>
            </button>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="navbardupli-burger-menu">
          <svg viewBox="0 0 1024 1024" className="navbardupli-icon10">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="navbardupli-mobile-menu">
          <div className="navbardupli-nav">
            <div className="navbardupli-top">
              <img
                alt={props.logoAlt1}
                src={props.logoSrc1}
                className="navbardupli-logo"
              />
              <div data-thq="thq-close-menu" className="navbardupli-close-menu">
                <svg viewBox="0 0 1024 1024" className="navbardupli-icon12">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="navbardupli-links2">
              <a href={props.link1Url} className="navbardupli-link1">
                {props.home ?? (
                  <Fragment>
                    <span className="navbardupli-text20 thq-link thq-body-small">
                      <span>Home</span>
                      <br></br>
                      <br></br>
                    </span>
                  </Fragment>
                )}
              </a>
              <a href={props.link2Url2}>
                {props.link22 ?? (
                  <Fragment>
                    <span className="navbardupli-text28 thq-link thq-body-small">
                      Link 1
                    </span>
                  </Fragment>
                )}
              </a>
              <a href={props.link3Url1}>
                {props.link31 ?? (
                  <Fragment>
                    <span className="navbardupli-text27 thq-link thq-body-small">
                      Link 3
                    </span>
                  </Fragment>
                )}
              </a>
              <div className="navbardupli-link4-accordion">
                <div
                  onClick={() => setLink5AccordionOpen(!link5AccordionOpen)}
                  className="navbardupli-trigger"
                >
                  <span>
                    {props.link41 ?? (
                      <Fragment>
                        <span className="navbardupli-text31 thq-link thq-body-small">
                          Link 4
                        </span>
                      </Fragment>
                    )}
                  </span>
                  <div className="navbardupli-icon-container">
                    {link5AccordionOpen && (
                      <div className="navbardupli-container2">
                        <svg
                          viewBox="0 0 1024 1024"
                          className="navbardupli-icon14"
                        >
                          <path d="M298 426h428l-214 214z"></path>
                        </svg>
                      </div>
                    )}
                    {!link5AccordionOpen && (
                      <div className="navbardupli-container3">
                        <svg
                          viewBox="0 0 1024 1024"
                          className="navbardupli-icon16"
                        >
                          <path d="M426 726v-428l214 214z"></path>
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
                {link5AccordionOpen && (
                  <div className="navbardupli-container4">
                    <a href={props.linkUrlPage1}>
                      <div className="navbardupli-menu-item1">
                        <img
                          alt={props.page1ImageAlt}
                          src={props.page1ImageSrc}
                          className="navbardupli-page1-image"
                        />
                        <div className="navbardupli-content1">
                          <span>
                            {props.page1 ?? (
                              <Fragment>
                                <span className="navbardupli-text15 thq-body-large">
                                  Page One
                                </span>
                              </Fragment>
                            )}
                          </span>
                          <span>
                            {props.page1Description ?? (
                              <Fragment>
                                <span className="navbardupli-text30 thq-body-small">
                                  Page One Description
                                </span>
                              </Fragment>
                            )}
                          </span>
                        </div>
                      </div>
                    </a>
                    <a href={props.linkUrlPage2}>
                      <div className="navbardupli-menu-item2">
                        <img
                          alt={props.page2ImageAlt}
                          src={props.page2ImageSrc}
                          className="navbardupli-page2-image"
                        />
                        <div className="navbardupli-content2">
                          <span>
                            {props.page2 ?? (
                              <Fragment>
                                <span className="navbardupli-text19 thq-body-large">
                                  Page Two
                                </span>
                              </Fragment>
                            )}
                          </span>
                          <span>
                            {props.page2Description ?? (
                              <Fragment>
                                <span className="navbardupli-text17 thq-body-small">
                                  Page Two Description
                                </span>
                              </Fragment>
                            )}
                          </span>
                        </div>
                      </div>
                    </a>
                    <a href={props.linkUrlPage3}>
                      <div className="navbardupli-menu-item3">
                        <img
                          alt={props.page3ImageAlt}
                          src={props.page3ImageSrc}
                          className="navbardupli-page3-image"
                        />
                        <div className="navbardupli-content3">
                          <span>
                            {props.page3 ?? (
                              <Fragment>
                                <span className="navbardupli-text24 thq-body-large">
                                  Page Three
                                </span>
                              </Fragment>
                            )}
                          </span>
                          <span>
                            {props.page3Description ?? (
                              <Fragment>
                                <span className="navbardupli-text34 thq-body-small">
                                  Page Three Description
                                </span>
                              </Fragment>
                            )}
                          </span>
                        </div>
                      </div>
                    </a>
                    <a href={props.linkUrlPage4}>
                      <div className="navbardupli-menu-item4">
                        <img
                          alt={props.page4ImageAlt}
                          src={props.page4ImageSrc}
                          className="navbardupli-page4-image"
                        />
                        <div className="navbardupli-content4">
                          <span>
                            {props.page4 ?? (
                              <Fragment>
                                <span className="navbardupli-text29 thq-body-large">
                                  Page Four
                                </span>
                              </Fragment>
                            )}
                          </span>
                          <span>
                            {props.page4Description ?? (
                              <Fragment>
                                <span className="navbardupli-text13 thq-body-small">
                                  Page Four Description
                                </span>
                              </Fragment>
                            )}
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                )}
              </div>
            </nav>
            <div className="navbardupli-buttons2">
              <button className="thq-button-filled">
                <span>
                  {props.action1 ?? (
                    <Fragment>
                      <span className="navbardupli-text18">Main Action</span>
                    </Fragment>
                  )}
                </span>
              </button>
              <button className="thq-button-outline">
                <span>
                  {props.action21 ?? (
                    <Fragment>
                      <span className="navbardupli-text25">Free Demo</span>
                    </Fragment>
                  )}
                </span>
              </button>
            </div>
          </div>
          <div className="navbardupli-icon-group">
            <svg
              viewBox="0 0 950.8571428571428 1024"
              className="thq-icon-x-small"
            >
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="thq-icon-x-small"
            >
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg
              viewBox="0 0 602.2582857142856 1024"
              className="thq-icon-small"
            >
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </div>
      </header>
    </header>
  )
}

Navbardupli.defaultProps = {
  link1Url: 'https://www.teleporthq.io',
  page4Description: undefined,
  linkUrlPage2: 'https://www.teleporthq.io',
  link21: undefined,
  page1: undefined,
  logoSrc: '/add%20a%20heading%20(9).svg',
  link32: undefined,
  link3Url1: 'https://www.teleporthq.io',
  page2Description: undefined,
  linkUrlPage1: 'https://www.teleporthq.io',
  action1: undefined,
  page2: undefined,
  home: undefined,
  linkUrlPage3: 'https://www.teleporthq.io',
  page3ImageSrc:
    'https://images.unsplash.com/photo-1524448789231-1bb0771e7d45?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDd8fHJvdW5kfGVufDB8fHx8MTcxNjM5OTM0NHww&ixlib=rb-4.0.3&w=200',
  page3: undefined,
  logoAlt1: 'logo',
  action21: undefined,
  link3: undefined,
  page2ImageAlt: 'image',
  page2ImageSrc:
    'https://images.unsplash.com/photo-1618034100983-e1d78be0dc80?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE3fHxyb3VuZHxlbnwwfHx8fDE3MTYzOTkzNDR8MA&ixlib=rb-4.0.3&w=200',
  link31: undefined,
  logoAlt: 'logo',
  page4ImageSrc:
    'https://images.unsplash.com/photo-1605745141160-8892dc674e94?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDI0fHxyb3VuZHxlbnwwfHx8fDE3MTYzOTkzNDR8MA&ixlib=rb-4.0.3&w=200',
  link22: undefined,
  page1ImageSrc:
    'https://images.unsplash.com/photo-1514285490982-4130e9aefedb?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDF8fHJvdW5kfGVufDB8fHx8MTcxNjM5OTM0NHww&ixlib=rb-4.0.3&w=200',
  page4: undefined,
  rootClassName: '',
  link2Url2: 'https://www.teleporthq.io',
  page1Description: undefined,
  link41: undefined,
  link2: undefined,
  page4ImageAlt: 'image',
  link321: undefined,
  page3Description: undefined,
  page3ImageAlt: 'image',
  action2: undefined,
  linkUrlPage4: 'https://www.teleporthq.io',
  logoSrc1: '/logo.svg',
  page1ImageAlt: 'image',
}

Navbardupli.propTypes = {
  link1Url: PropTypes.string,
  page4Description: PropTypes.element,
  linkUrlPage2: PropTypes.string,
  link21: PropTypes.element,
  page1: PropTypes.element,
  logoSrc: PropTypes.string,
  link32: PropTypes.element,
  link3Url1: PropTypes.string,
  page2Description: PropTypes.element,
  linkUrlPage1: PropTypes.string,
  action1: PropTypes.element,
  page2: PropTypes.element,
  home: PropTypes.element,
  linkUrlPage3: PropTypes.string,
  page3ImageSrc: PropTypes.string,
  page3: PropTypes.element,
  logoAlt1: PropTypes.string,
  action21: PropTypes.element,
  link3: PropTypes.element,
  page2ImageAlt: PropTypes.string,
  page2ImageSrc: PropTypes.string,
  link31: PropTypes.element,
  logoAlt: PropTypes.string,
  page4ImageSrc: PropTypes.string,
  link22: PropTypes.element,
  page1ImageSrc: PropTypes.string,
  page4: PropTypes.element,
  rootClassName: PropTypes.string,
  link2Url2: PropTypes.string,
  page1Description: PropTypes.element,
  link41: PropTypes.element,
  link2: PropTypes.element,
  page4ImageAlt: PropTypes.string,
  link321: PropTypes.element,
  page3Description: PropTypes.element,
  page3ImageAlt: PropTypes.string,
  action2: PropTypes.element,
  linkUrlPage4: PropTypes.string,
  logoSrc1: PropTypes.string,
  page1ImageAlt: PropTypes.string,
}

export default Navbardupli
