import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './faq121.css'

const FAQ121 = (props) => {
  const [faq2Visible, setFaq2Visible] = useState(false)
  const [faq1Visible, setFaq1Visible] = useState(false)
  const [faq3Visible, setFaq3Visible] = useState(false)
  const [faq4Visible, setFaq4Visible] = useState(false)
  return (
    <div className="faq121-faq8 thq-section-padding">
      <div className="faq121-max-width thq-flex-row thq-section-max-width">
        <div className="faq121-section-title thq-flex-column">
          <div className="faq121-content thq-flex-column">
            <h2>
              {props.heading1 ?? (
                <Fragment>
                  <h2 className="faq121-text19 thq-heading-2">
                    Frequently Asked Questions
                  </h2>
                </Fragment>
              )}
            </h2>
            <p>
              {props.content1 ?? (
                <Fragment>
                  <p className="faq121-text23 thq-body-large">
                    For more information or specific inquiries, please feel free
                    to reach out to our team through the contact information
                    provided.
                  </p>
                </Fragment>
              )}
            </p>
          </div>
        </div>
        <div className="faq121-list thq-flex-column">
          <div className="faq121-faq1">
            <div
              onClick={() => setFaq1Visible(!faq1Visible)}
              className="faq121-trigger1"
            >
              <p>
                {props.faq1Question ?? (
                  <Fragment>
                    {!faq1Visible && (
                      <p className="faq121-text24 thq-body-large">
                        What makes your CRM platform different from others?
                      </p>
                    )}
                  </Fragment>
                )}
              </p>
              <div className="faq121-icons-container1">
                {!faq1Visible && (
                  <div>
                    <svg viewBox="0 0 1024 1024" className="faq121-icon10">
                      <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                    </svg>
                  </div>
                )}
                {faq1Visible && (
                  <div>
                    <svg viewBox="0 0 1024 1024" className="faq121-icon12">
                      <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                    </svg>
                  </div>
                )}
              </div>
            </div>
            {faq1Visible && (
              <div className="faq121-container12">
                <span className="thq-body-small">
                  Our CRM leverages AI insights and dynamic automation to
                  enhance customer engagement and streamline operations more
                  effectively
                </span>
              </div>
            )}
          </div>
          <div className="faq121-faq2">
            <div
              onClick={() => setFaq2Visible(!faq2Visible)}
              className="faq121-trigger2"
            >
              <p>
                {props.faq2Question ?? (
                  <Fragment>
                    <p className="faq121-text21 thq-body-large">
                      Can I integrate the CRM with my existing tools?
                    </p>
                  </Fragment>
                )}
              </p>
              <div className="faq121-icons-container2">
                <div>
                  <svg viewBox="0 0 1024 1024" className="faq121-icon14">
                    <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                  </svg>
                </div>
                {faq2Visible && (
                  <div>
                    <svg viewBox="0 0 1024 1024" className="faq121-icon16">
                      <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                    </svg>
                  </div>
                )}
              </div>
            </div>
            {faq2Visible && (
              <div className="faq121-container15">
                <span className="thq-body-small">
                  <span>
                    Yes, our CRM supports seamless integration with various
                    business applications for a unified workflow
                  </span>
                  <br></br>
                  <br></br>
                </span>
              </div>
            )}
          </div>
          <div className="faq121-faq3">
            <div
              onClick={() => setFaq3Visible(!faq3Visible)}
              className="faq121-trigger3"
            >
              <p>
                {props.faq3Question ?? (
                  <Fragment>
                    <p className="faq121-text20 thq-body-large">
                      How secure is your CRM platform?
                    </p>
                  </Fragment>
                )}
              </p>
              <div className="faq121-icons-container3">
                {!faq3Visible && (
                  <div>
                    <svg viewBox="0 0 1024 1024" className="faq121-icon18">
                      <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                    </svg>
                  </div>
                )}
                {faq3Visible && (
                  <div>
                    <svg viewBox="0 0 1024 1024" className="faq121-icon20">
                      <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                    </svg>
                  </div>
                )}
              </div>
            </div>
            {faq3Visible && (
              <div className="faq121-container18">
                <span className="thq-body-small">
                  Our platform uses advanced security protocols to protect your
                  data, ensuring compliance with industry standards and
                  safeguarding sensitive information
                </span>
              </div>
            )}
          </div>
          <div className="faq121-faq4">
            <div
              onClick={() => setFaq4Visible(!faq4Visible)}
              className="faq121-trigger4"
            >
              <p>
                {props.faq4Question ?? (
                  <Fragment>
                    <p className="faq121-text22 thq-body-large">
                      What kind of support do you offer?
                    </p>
                  </Fragment>
                )}
              </p>
              <div className="faq121-icons-container4">
                {!faq4Visible && (
                  <div>
                    <svg viewBox="0 0 1024 1024" className="faq121-icon22">
                      <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                    </svg>
                  </div>
                )}
                {faq4Visible && (
                  <div>
                    <svg viewBox="0 0 1024 1024" className="faq121-icon24">
                      <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                    </svg>
                  </div>
                )}
              </div>
            </div>
            {faq4Visible && (
              <div className="faq121-container21">
                <span className="thq-body-small">
                  We provide email, phone, and dedicated account management
                  support based on your plan, assisting with any issues or
                  questions
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

FAQ121.defaultProps = {
  heading1: undefined,
  faq3Question: undefined,
  faq2Question: undefined,
  faq4Question: undefined,
  content1: undefined,
  faq1Question: undefined,
}

FAQ121.propTypes = {
  heading1: PropTypes.element,
  faq3Question: PropTypes.element,
  faq2Question: PropTypes.element,
  faq4Question: PropTypes.element,
  content1: PropTypes.element,
  faq1Question: PropTypes.element,
}

export default FAQ121
