import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './effifeatures.css'

const EffiFeature = (props) => {
  return (
    <div
      id="efeatures"
      className={`feature-section-layout349 thq-section-padding ${props.rootClassName} `}
    >
      <div className="feature-section-max-width thq-section-max-width">
        <div className="effifeature-section-container1">
          <h2>
            {props.heading1 ?? (
              <Fragment>
                <h2 className="feature-section-text25">
                Features that Set EffiTrack AI Apart
                </h2>
              </Fragment>
            )}
          </h2>
          <span>
            {props.content1 ?? (
              <Fragment>
                <span className="feature-section-text34 thq-body-small">
                  {/* <span>
                    Experience comprehensive CRM capabilities designed to
                    enhance efficiency, personalize interactions, and ensure
                    robust security for optimal business performance
                  </span> */}
                  <br className="feature-section-text36"></br>
                  <br></br>
                </span>
              </Fragment>
            )}
          </span>
        </div>
        <div className="feature-section-container2 thq-grid-auto-300">
          
            <div
              data-thq-animate-on-reveal="true"
              className="feature-section-card1 thq-card thq-flex-column"
            >
              <img
                alt={props.feature1ImageAlt}
                src={props.feature1ImageSrc}
                className="feature-section-image1 thq-img-ratio-1-1 thq-img-round"
              />
              <h2>
                {props.feature1Title ?? (
                  <Fragment>
                    <h2 className="feature-section-text27 thq-heading-2">
                    Smart Task<br/>Prioritization
                    </h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.feature1Description ?? (
                  <Fragment>
                    <span className="feature-section-text28 thq-body-small">
                      <span>
                      Our AI analyzes task deadlines, dependencies, and user workloads to dynamically prioritize your tasks. Focus on high-priority tasks and maximize your productivity.
                      </span>
                      <br></br>
                      <br></br>
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          
          <div className="feature-section-card2 thq-card thq-flex-column">
            <img
              alt={props.feature2ImageAlt}
              src={props.feature2ImageSrc}
              className="feature-section-image2 thq-img-ratio-1-1 thq-img-round"
            />
            <h2>
              {props.feature2Title ?? (
                <Fragment>
                  <h2 className="feature-section-text33 thq-heading-2">
                  Predictive Time<br/>Tracking
                  </h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.feature2Description ?? (
                <Fragment>
                  <span className="feature-section-text48 thq-body-small">
                    <span>
                    EffiTrack AI predicts the time required for each task based on historical data and individual performance metrics. Stay ahead by knowing exactly how long each task will take.
                    </span>
                    <br></br>
                    <br></br>
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="feature-section-card3 thq-card thq-flex-column">
            <img
              alt={props.feature3ImageAlt}
              src={props.feature3ImageSrc}
              className="feature-section-image3 thq-img-ratio-1-1 thq-img-round"
            />
            <h1>
              {props.feature3Title ?? (
                <Fragment>
                  <h1 className="feature-section-text26 thq-heading-2">
                  Real-Time<br/>Adaptation
                  </h1>
                </Fragment>
              )}
            </h1>
            <span>
              {props.feature3Description ?? (
                <Fragment>
                  <span className="feature-section-text38 thq-body-small">
                  EffiTrack AI adapts to changes in your workload and priorities in real-time, ensuring that your task list is always optimized.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
        <div className="feature-section-container3 thq-grid-auto-300">
          <div className="feature-section-card4 thq-card thq-flex-column">
            <img
              alt={props.feature4ImageAlt1}
              src={props.feature4ImageSrc1}
              className="feature-section-image4 thq-img-ratio-1-1 thq-img-round"
            />
            <h1>
              {props.feature4Title1 ?? (
                <Fragment>
                  <h1 className="feature-section-text32 thq-heading-2">
                  Personalized<br/>Suggestions
                  </h1>
                </Fragment>
              )}
            </h1>
            <span>
              {props.feature4Description1 ?? (
                <Fragment>
                  <span className="feature-section-text39 thq-body-small">
                    <span>
                    Receive tailored recommendations to improve your time management, helping you achieve your goals more efficiently.
                    </span>
                    <br></br>
                    <br></br>
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="feature-section-card5 thq-card thq-flex-column">
            <img
              alt={props.feature5ImageAlt2}
              src={props.feature5ImageSrc2}
              className="feature-section-image5 thq-img-ratio-1-1 thq-img-round"
            />
            <h1>
              {props.feature5Title2 ?? (
                <Fragment>
                  <h1 className="feature-section-text52 thq-heading-2">
                  Efficiency<br/>Monitoring
                  </h1>
                </Fragment>
              )}
            </h1>
            <span>
              {props.feature5Description2 ?? (
                <Fragment>
                  <span className="feature-section-text44 thq-body-small">
                    <span>
                    Track your progress and adjust future predictions and prioritizations for continuous improvement.
                    </span>
                    <br></br>
                    <br></br>
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          {/* <div className="feature-section-card6 thq-card thq-flex-column">
            <img
              alt={props.feature5ImageAlt11}
              src={props.feature5ImageSrc11}
              className="feature-section-image6 thq-img-ratio-1-1 thq-img-round"
            />
            <h1>
              {props.feature5Title11 ?? (
                <Fragment>
                  <h1 className="feature-section-text24 thq-heading-2">
                    Robust Security Protocols
                  </h1>
                </Fragment>
              )}
            </h1>
            <span>
              {props.feature5Description11 ?? (
                <Fragment>
                  <span className="feature-section-text43 thq-body-small">
                    Safeguard sensitive information with cutting-edge security
                    features, ensuring data protection and compliance with
                    industry standards.
                  </span>
                </Fragment>
              )}
            </span>
          </div> */}
        </div>
      </div>
    </div>
  )
}

EffiFeature.defaultProps = {
  feature5Title11: undefined,
  heading1: undefined,
  feature3Title: undefined,
  feature1Title: undefined,
  feature1ImageSrc: 'images/Predictive%20Analytics.svg',
  feature2ImageAlt: '24/7 Customer Support Image Alt Text',
  feature1Description: undefined,
  feature4Title1: undefined,
  rootClassName: '',
  feature2Title: undefined,
  feature5ImageSrc11: 'images/robust%20security%20protocols-1500w.png',
  content1: undefined,
  feature5ImageAlt11: 'User-Friendly Interface Image Alt Text',
  feature3Description: undefined,
  feature5ImageAlt2: 'User-Friendly Interface Image Alt Text',
  feature4Description1: undefined,
  feature4ImageAlt1: 'Advanced Security Image Alt Text',
  feature1ImageAlt: 'Customizable Plans Image Alt Text',
  feature5ImageSrc2: 'images/Advanced Personalization.svg',
  feature4ImageSrc1: 'images/Dynamic%20Automation.svg',
  feature5Description11: undefined,
  feature5Description2: undefined,
  feature3ImageAlt: 'Multiple Locations Image Alt Text',
  feature2Description: undefined,
  feature3ImageSrc: 'images/Centralized Data Hub.svg',
  feature5Title2: undefined,
  feature2ImageSrc: 'images/monitor.svg',
}

EffiFeature.propTypes = {
  feature5Title11: PropTypes.element,
  heading1: PropTypes.element,
  feature3Title: PropTypes.element,
  feature1Title: PropTypes.element,
  feature1ImageSrc: PropTypes.string,
  feature2ImageAlt: PropTypes.string,
  feature1Description: PropTypes.element,
  feature4Title1: PropTypes.element,
  rootClassName: PropTypes.string,
  feature2Title: PropTypes.element,
  feature5ImageSrc11: PropTypes.string,
  content1: PropTypes.element,
  feature5ImageAlt11: PropTypes.string,
  feature3Description: PropTypes.element,
  feature5ImageAlt2: PropTypes.string,
  feature4Description1: PropTypes.element,
  feature4ImageAlt1: PropTypes.string,
  feature1ImageAlt: PropTypes.string,
  feature5ImageSrc2: PropTypes.string,
  feature4ImageSrc1: PropTypes.string,
  feature5Description11: PropTypes.element,
  feature5Description2: PropTypes.element,
  feature3ImageAlt: PropTypes.string,
  feature2Description: PropTypes.element,
  feature3ImageSrc: PropTypes.string,
  feature5Title2: PropTypes.element,
  feature2ImageSrc: PropTypes.string,
}

export default EffiFeature
