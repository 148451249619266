import React, { useState } from 'react';
import './effidemo.css';

const DemoSection = () => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [analysisDone, setAnalysisDone] = useState(false);
  const [tasks, setTasks] = useState([]);

  const handleFileUpload = () => {
    setFileUploaded(true);

    // Simulate AI processing delay
    setTimeout(() => {
      setAnalysisDone(true);
      // Simulated task data for demo purposes
      const sampleTasks = [
        { name: 'Sorry Currently this Demo Service Unavailable', priority: '', time: '', order: '' },
        { name: '', priority: '', time: '', order: '' },
        { name: '', priority: '', time: '', order: '' }
      ];
      setTasks(sampleTasks);
    }, 3000); // Simulate 3-second delay
  };

  const handleDownload = () => {
    const csvContent = [
      ["Task Name", "Priority Level", "Predicted Time", "Suggested Order"],
      ...tasks.map(task => [task.name, task.priority, task.time, task.order])
    ].map(e => e.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "optimized_tasks.csv");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleReset = () => {
    setFileUploaded(false);
    setAnalysisDone(false);
    setTasks([]);
  };

  return (
    <div id="demo" className="demo-section">
      <h2>See EffiTrack AI in Action</h2>
      <p>Experience the power of AI-driven task optimization. Upload your task list and watch EffiTrack AI prioritize and predict completion times in real-time.</p>
      
      {/* Step 1: Upload CSV File */}
      {!fileUploaded && (
        <div className="upload-section">
          <h3>Step 1: Upload Your Task List</h3>
          <input 
            type="file" 
            accept=".csv" 
            onChange={handleFileUpload} 
          />
        </div>
      )}
      
      {/* Step 2: AI Analysis */}
      {fileUploaded && !analysisDone && (
        <div className="analysis-section">
          <h3>Step 2: AI Analysis</h3>
          <p>EffiTrack AI is analyzing your tasks...</p>
          <div className="progress-bar">
            <div className="progress-bar-fill"></div>
          </div>
        </div>
      )}
      
      {/* Step 3: Display Prioritized Task List */}
      {analysisDone && (
        <div className="result-section">
          <h3>Step 3: View Prioritized Task List</h3>
          
          <h3 style={{
    color: '#ff4d4f',          // Red color to indicate an error or warning
    fontSize: '24px',          // Larger font size for emphasis
    fontWeight: 'bold',        // Bold font for prominence
    textAlign: 'center',       // Centered text
    marginTop: '20px',         // Spacing above the message
    padding: '10px',           // Padding around the text for better readability
    backgroundColor: '#f8d7da', // Light red background to highlight the message
    borderRadius: '5px'        // Slightly rounded corners for a modern look
}}>
    Sorry, Currently This Service Unavailable
</h3>
        </div>
      )}
      
      {/* Step 4: Download the Optimized Task List */}
      {analysisDone && (
        <div className="download-section">
          <h3>Step 4: Download the Optimized Task List</h3>
          <button onClick={handleDownload}>Download CSV</button>
        </div>
      )}

      {/* Step 5: Start Over */}
      {analysisDone && (
        <div className="reset-section">
          <h3>Step 5: Start Over</h3>
          <button onClick={handleReset}>Upload New CSV</button>
        </div>
      )}
    </div>
  );
};

export default DemoSection;
