

import './why-choose-us.css'



import React from 'react';


const WhyChooseUs = () => {
    return (
      <section id="why" className="about section accent-background">
      <div className="container position-relative" style={{ width: '85%', maxWidth: '100%', margin: '0 auto' }} data-aos="fade-up" data-aos-delay="100">
          <div className="row gy-5 justify-content-between">
              <div className="col-lg-6 order-1 order-lg-2">
                  <img src="images/untitled design (65)-500h.png" className="img-fluid" alt="Why choose us"  />
              </div>
              <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                  <h2>
                      <span>Meet the CRM Visionaries</span>
                      
                  </h2>
                  <h4>
                      <span>Revolutionizing Customer Management</span>
                  </h4>
                  <p>
                  We are pioneers in CRM technology, dedicated to delivering cutting-edge solutions that drive business growth. Our platform combines advanced AI with intuitive design to revolutionize how businesses manage and nurture customer relationships.
                  </p>
                  {/* Adding 3 points under the paragraph */}
                  <ul className="points-list">
                      <li>Advanced AI Integration</li>
                      <li>Intuitive User Experience</li>
                      <li>Scalable Solutions</li>
                  </ul>
              </div>
          </div>
      </div>
  </section>
  



        

    );
};

export default WhyChooseUs;
