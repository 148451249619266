import React from 'react';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import './footernew.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS

const Footer = () => {
  return (
    <footer id="footer" className="footer accent-background">
      <div className="container footer-top">
        <div className="row gy-4">
          {/* First Column - Logo and Description */}
          <div className="col-lg-5 col-md-6 footer-about " >
            <a href="/" className="logo d-flex align-items-center">
            <img src="images/logonew.svg" alt="Company Logo"  className="footer footer-about logo"/>
              {/* Uncomment and update the src path to your actual logo path */}
              {/* <img src={require('../assets/img/logo_d.svg').default} alt="Company Logo" className="" /> */}
            </a>
            <p>
            Enhance your business with cutting-edge AI CRM—automate workflows, tailor interactions, and propel growth.
            </p>
          </div>

          {/* Second Column - Company Links */}
          <div className="col-lg-2 col-md-6 footer-links">
            <h4>Company</h4>
            <ul>
              <li><a href="/">Home
              </a></li>
              <li><a href="/#aboutus">About Us</a></li>
              <li><a href="/#features">Features</a></li>
              <li><a href="/#pricingplan">Pricing Plan</a></li>
              <li><a href="/#contact">Contact Us</a></li>
            </ul>
          </div>

          {/* Third Column - Page Links */}
          <div className="col-lg-2 col-md-6 footer-links">
            <h4>Pages</h4>
            <ul>
              <li><a href="/privacy-policy">Privacy Policy</a></li>
              <li><a href="/terms-conditions">Terms & Conditions</a></li>
            </ul>
          </div>

          {/* Fourth Column - Subscribe and Social Media */}
          <div className="col-lg-3 col-md-6 footer-subscribe">
            <h4>Subscribe</h4>
            <p>Stay updated with our latest news and offers. Subscribe to our newsletter.</p>
            <form action="" method="post">
              <input type="email" name="email" placeholder="Enter your email" required />
              <button type="submit">Subscribe</button>
            </form>
            {/* Social Media Links */}
            <div className="social-links mt-3">
              <a href="https://x.com/itgroundtech" target="_blank" rel="noopener noreferrer" className="social-icon">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://web.facebook.com/ITgroundtechnologie" target="_blank" rel="noopener noreferrer" className="social-icon">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://www.youtube.com/@ITGroundTechnologies" target="_blank" rel="noopener noreferrer" className="social-icon">
                <i className="fab fa-youtube"></i>
              </a>
              <a href="https://www.linkedin.com/company/it-ground-technologies/" target="_blank" rel="noopener noreferrer" className="social-icon">
                <i className="fab fa-linkedin-in"></i>
              </a>
              
            </div>
          </div>
        </div>
      </div>

      <div className="container copyright text-center mt-4">
        <p>© 2021-2024 <strong><a href="/">Itground.net</a></strong>. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
