import './effitrack1.css'
import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'
import Nav from '../components/header1'
import Navbardupli from '../components/navbardupli'
import HeroSectionDupli from '../components/hero-section-dupli'
import Effihero from '../components/effihero'
import EffiFeature from '../components/effifeatures'
import EffiBenefit from '../components/effibenefit'
import DemoSection from '../components/effidemo'
import Footer from '../components/footernew'
import Testimonials from '../components/effitesti'

const Effitrack = (props) => {
    return (
      <div className="home-container">
     
        <Nav/>
        <Effihero/>
        <DemoSection/>

        <EffiFeature/>
        <EffiBenefit/>
        <Testimonials/>
        <Footer/>
 
    </div>
    )
  }
  
  export default Effitrack;