import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import FAQ121 from './faq121'
import './faqs.css'

const Faqs = (props) => {
  return (
    <div className={`faqs-container ${props.rootClassName} `}>
      <FAQ121
        content1={
          <Fragment>
            <p className="faqs-text1 thq-body-large">
              Find quick answers to common questions about our CRM platform,
              from unique features and integrations to security and support
              options
            </p>
          </Fragment>
        }
        heading1={
          <Fragment>
            <h2 className="faqs-text2 thq-heading-2">
              Frequently Asked Questions
            </h2>
          </Fragment>
        }
        faq1Question={
          <Fragment>
            <p className="faqs-text3 thq-body-large">
              What are the available pricing plans?
            </p>
          </Fragment>
        }
        faq2Question={
          <Fragment>
            <p className="faqs-text4 thq-body-large">
              Can I integrate the CRM with my existing tools?
            </p>
          </Fragment>
        }
        faq3Question={
          <Fragment>
            <p className="faqs-text5 thq-body-large">
              How secure is your CRM platform?
            </p>
          </Fragment>
        }
        faq4Question={
          <Fragment>
            <p className="faqs-text6 thq-body-large">
              What kind of support do you offer?
            </p>
          </Fragment>
        }
      ></FAQ121>
    </div>
  )
}

Faqs.defaultProps = {
  rootClassName: '',
}

Faqs.propTypes = {
  rootClassName: PropTypes.string,
}

export default Faqs
