import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbardupli from '../components/navbardupli'
import HeroPrivacy from '../components/hero-privacy'
import BodyPrivacy from '../components/body-privacy'
import Footernew from '../components/footernew'
import './privacy-policy.css'

const PrivacyPolicy = (props) => {
  return (
    <div id="privacypolicy" className="privacy-policy-container">
      <Helmet>
        <title>Privacy-Policy - ITGround.net</title>
        <meta property="og:title" content="Privacy-Policy - ITGround.net" />
      </Helmet>
      <Navbardupli
        home={
          <Fragment>
            <span className="privacy-policy-text10 thq-link thq-body-small">
              <span>Home</span>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="privacy-policy-text14 thq-link thq-body-small">
              Home
            </span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="privacy-policy-text15 thq-link thq-body-small">
              Features
            </span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="privacy-policy-text16 thq-body-large">
              Page One
            </span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="privacy-policy-text17 thq-body-large">
              Page Two
            </span>
          </Fragment>
        }
        page3={
          <Fragment>
            <span className="privacy-policy-text18 thq-body-large">
              Page Three
            </span>
          </Fragment>
        }
        page4={
          <Fragment>
            <span className="privacy-policy-text19 thq-body-large">
              Page Four
            </span>
          </Fragment>
        }
        link21={
          <Fragment>
            <span className="privacy-policy-text20 thq-link thq-body-small">
              About Us
            </span>
          </Fragment>
        }
        link22={
          <Fragment>
            <span className="privacy-policy-text21 thq-link thq-body-small">
              Link 1
            </span>
          </Fragment>
        }
        link31={
          <Fragment>
            <span className="privacy-policy-text22 thq-link thq-body-small">
              Link 3
            </span>
          </Fragment>
        }
        link32={
          <Fragment>
            <span className="privacy-policy-text23 thq-link thq-body-small">
              Pricing Plan
            </span>
          </Fragment>
        }
        link41={
          <Fragment>
            <span className="privacy-policy-text24 thq-link thq-body-small">
              Link 4
            </span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="privacy-policy-text25">Main Action</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="privacy-policy-text26">Free Demo</span>
          </Fragment>
        }
        link321={
          <Fragment>
            <span className="privacy-policy-text27 thq-link thq-body-small">
              Contact Us
            </span>
          </Fragment>
        }
        action21={
          <Fragment>
            <span className="privacy-policy-text28">Free Demo</span>
          </Fragment>
        }
        rootClassName="navbardupli-root-class-name1"
        page1Description={
          <Fragment>
            <span className="privacy-policy-text29 thq-body-small">
              Page One Description
            </span>
          </Fragment>
        }
        page2Description={
          <Fragment>
            <span className="privacy-policy-text30 thq-body-small">
              Page Two Description
            </span>
          </Fragment>
        }
        page3Description={
          <Fragment>
            <span className="privacy-policy-text31 thq-body-small">
              Page Three Description
            </span>
          </Fragment>
        }
        page4Description={
          <Fragment>
            <span className="privacy-policy-text32 thq-body-small">
              Page Four Description
            </span>
          </Fragment>
        }
      ></Navbardupli>
      <HeroPrivacy
        heading1={
          <Fragment>
            <h1 className="privacy-policy-text33 thq-heading-1">
              Privacy Policy
            </h1>
          </Fragment>
        }
        rootClassName="hero-privacy-root-class-name"
      ></HeroPrivacy>
      <BodyPrivacy rootClassName="body-privacy-root-class-name"></BodyPrivacy>
      <Footernew rootClassName="footernew-root-class-name1"></Footernew>
    </div>
  )
}

export default PrivacyPolicy
